<template>
  <div class="make">
    <div class="content">
      <!-- 元素 -->
      <div class="element">
        <div style="z-index: 11">
          <img draggable="true" id='image' src="../../assets/invitimages.png" alt="">
        </div>
        <div style="z-index: 11">
          <img draggable="true" id='text' src="../../assets/invittext.png" alt="">
        </div>
        <div style="z-index: 11">
          <div @click="topping" title="元素置顶">顶</div>
        </div>
        <div style="z-index: 11">
          <div @click="bottomSetting" title="元素置底">底</div>
        </div>
        <div style="z-index: 11">
          <div @click="alignTop" title="向上对齐">上</div>
        </div>
        <div style="z-index: 11">
          <div @click="alignBottom" title="向下对齐">下</div>
        </div>
        <div style="z-index: 11">
          <div @click="alignLeft" title="向左对齐">左</div>
        </div>
        <div style="z-index: 11">
          <div @click="alignRight" title="向右对齐">右</div>
        </div>
        <div style="z-index: 11">
          <div @click="showBorder" title="边框显示状态">{{ borderStatus ? '隐藏' : '显示'}}</div>
        </div>

        <div style="z-index: 11" class="card-side">
          <div class="switchBool">
            <el-checkbox v-model="switchBool">单面</el-checkbox>
          </div>
          <el-checkbox v-model="model.plate" true-label="twoSided" false-label="oneSide">双面</el-checkbox>
        </div>

        <div style="z-index: 11" class="card-side" v-if="!curCardModel.finalize">
          <div class="lock" @click="disableShade = !disableShade">
            <i class="el-icon-unlock" v-if="disableShade"></i>
            <i class="el-icon-lock" v-else></i>
          </div>
          <!-- <el-checkbox v-model="disableShade">禁用</el-checkbox> -->
        </div>
        
      </div>
      <!-- 尺子 -->
      <Ruler :zoom="zoom" :disableShade='disableShade' @dblclick='modelBg'>
        <div class="model-bg" @dblclick="modelBg" :style="{'width': model.width * bl + 'px', 'height': model.height * bl + 'px'}" @click.self="eleBlurSelect">

          <!-- 模子 -->
          <div class="mold template select" tabindex="1">
            <div class="cte"></div>
            <div class="rightBottom"></div>
          </div>

        </div>

        <div class="back-model-bg" v-if="model.plate == 'twoSided'" @dblclick="modelBg" :style="{'width': model.width * bl + 'px', 'height': model.height * bl + 'px'}" @click.self="eleBlurSelect">

          <!-- 模子 -->
          <div class="mold template select" tabindex="1">
            <div class="cte"></div>
            <div class="rightBottom"></div>
          </div>

        </div>
        <!-- <span title="元素运行轨迹" v-if="curObj.id" class="trajectory">({{ curObj.x }}, {{ curObj.y }})</span> -->
      </Ruler>

      <!-- 元素列表 -->
      <div class="ele-list">
        <div class="fixed">
          <div class="btn" @click="submit">新&nbsp;&nbsp;&nbsp;&nbsp;建</div>
          <div class="btn"  @click="file_child = true">文&nbsp;&nbsp;&nbsp;&nbsp;件</div>
          <div :class="['btn', (useModel || curCardModel.finalize) && 'not-allowed' ]" @click="saveFile">保存文件</div>
          <div class="btn" @click="saveAs">另存文件</div>
          <div :class="['btn', useModel ? 'not-allowed' : !curCardModel.finalize ? 'special' : 'not-allowed']" @click="finalize">定&nbsp;&nbsp;&nbsp;&nbsp;稿</div>
          <div class="btn" @click="sendCard">协同制作</div>
          
          <div class="margin-top">
            <div class="btn" @click="model_child = true">模&nbsp;&nbsp;&nbsp;&nbsp;板</div>
            <div :class="['btn', curCardModel.finalize && 'not-allowed' ]" @click="story">另存模板</div>
          </div>
        </div>

        <div :class="['single', its.variable && 'varName', its.id == curObj.id && 'select']" v-for="(its, idx) in collection" :key="idx" @click="selectCur(its)" @dblclick="dblclick(its)"> 
          {{ (variObj[its.variable] || its.conten || its.eleType).substr(0, 8) }} </div>
      </div>
    </div>

    <!-- 图片属性修改 -->
    <el-dialog title="图片属性" :visible.sync="imageAttr" width="600px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='100px'>
      <div class="" v-if="imageAttr">
        <div class="category">基本属性</div>
        <div class="model-attr">
          <div class="single">
            <span class="label">图片：</span>
            <div class="value">
              <el-button size="small" v-if="!curObj.image" @click="updateImg"> 点击上传 </el-button>
              <el-button size="small" v-else @click="removeImg"> 去除图片 </el-button>
            </div>
          </div>
          <div class="single">
            <span class="label">变量：</span>
            <div class="value">
              <el-select v-model="curObj.variable" placeholder="请选择" size="small" @change="curObjVari">
                <el-option
                  v-for="(its, idx) in variArr.image" :key="idx"
                  :label="its.name" :value="its.scription">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="single">
            <span class="label">宽度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.width" :step="0.01" :min="1" @change='curObjWidth'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">X坐标：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.x" :step="0.01" :min="0" @change='curObjX'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">高度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.height" :step="0.01" :min="1" @change='curObjHeight'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">Y坐标：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.y" :step="0.01" :min="0" @change='curObjY'></el-input-number>
            </div>
          </div>
          <!-- <div class="single">
            <span class="label">透明度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.opacity" :min="1" :max='100' @change='curObjOpacity'></el-input-number>
            </div>
          </div> -->
          <!-- <div class="single">
            <el-button size="small" @click="equalWidth"> 等宽 </el-button>
            <el-button size="small" @click="equalHeight"> 等高 </el-button>
          </div> -->
          <div class="single">
            <el-button size="small" @click="horizontally"> 水平居中 </el-button>
            <el-button size="small" @click="verticalCenter"> 垂直居中 </el-button>
          </div>
          <!-- <div class="single">
            <el-button size="small" @click="topping"> 置顶 </el-button>
            <el-button size="small" @click="bottomSetting"> 置底 </el-button>
          </div> -->
        </div>

        <div class="category" v-if="!curObj.variable">边框属性</div>
        <div class="model-attr" v-if="!curObj.variable">
          <div class="single">
            <span class="label">边框形状：</span>
            <div class="value">
              <el-select v-model="curObj.border.style" placeholder="请选择" size="small" @change="curObjBorderS">
                <el-option
                  v-for="(its, idx) in borderSArr" :key="idx"
                  :label="its.name" :value="its.scription">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="single">
            <span class="label">边框尺寸：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.border.width" :min="0" @change='curObjBorderW'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">边框颜色：</span>
            <div class="value">
              <colorPicker v-model="curObj.border.color" @change="curObjBorderC"/>
            </div>
          </div>
          <div class="single">
            <span class="label">边框弧度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.border.radius" :min="0" @change='curObjBorderR'></el-input-number>
            </div>
          </div>
        </div>

        <div class="category">阴影属性</div>
        <div class="model-attr">
          <div class="single">
            <span class="label">阴影大小：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.boxshadow.size" :min="0" @change='curObjBoxshadow'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">水平距离：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.boxshadow.x" :min="0" @change='curObjBoxshadow'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">模糊程度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.boxshadow.vague" :min="0" @change='curObjBoxshadow'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">垂直距离：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.boxshadow.y" :min="0" @change='curObjBoxshadow'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">边框颜色：</span>
            <div class="value">
              <colorPicker v-model="curObj.boxshadow.color" @change="curObjBoxshadow"/>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 文本属性修改 -->
    <el-dialog title="文本属性" :visible.sync="textAttr" width="600px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='100px'>
      <div class="" v-if="textAttr">
        <div class="category">基本属性</div>
        <div class="model-attr">
          <div class="single">
            <span class="label">文本内容：</span>
            <div class="value">
              <el-input v-model="curObj.conten" placeholder="请输入内容" size="small" @input="curObjCte"></el-input>
            </div>
          </div>
          <div class="single">
            <span class="label">变量：</span>
            <div class="value">
              <el-select v-model="curObj.variable" placeholder="请选择" size="small" @change="curObjVari">
                <el-option
                  v-for="(its, idx) in variArr.text" :key="idx"
                  :label="its.name" :value="its.scription">
                </el-option>
              </el-select>
            </div>
          </div>
          
          <div class="single">
            <span class="label">字体大小：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.font.size" :min="12" @change='curObjFontSize'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">字体类型：</span>
            <div class="value">
              <el-select v-model="curObj.font.family" placeholder="请选择" size="small" @change="curObjFamily">
                <el-option
                  v-for="(its, idx) in family" :key="idx"
                  :label="its.key" :value="its.value">
                </el-option>
              </el-select>
            </div>
          </div>
          
        </div>
        <br/>
        <div class="model-attr">
          <!-- <div class="single">
            <span class="label">透明度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.opacity" :min="1" :max='100' @change='curObjOpacity'></el-input-number>
            </div>
          </div> -->
          <div class="single">
            <span class="label">字体间距：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.font.spacing" :min="0" @change='curObjFontSpacing'></el-input-number>
            </div>
          </div>
          <!-- <div class="single">
            <span class="label">字体行高：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.font.lineHeight" :min="0" @change='curObjFontLineHeight'></el-input-number>
            </div>
          </div> -->
          <div class="single">
            <span class="label">字体颜色：</span>
            <div class="value">
              <colorPicker v-model="curObj.font.color" @change="curObjFontLineColor"/>
            </div>
          </div>
          <div class="single">
            <span class="label">X坐标：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.x" :step="0.01" :min="0" @change='curObjX'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">宽度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.width" :step="0.01" :min="1" @change='curObjWidth'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">Y坐标：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.y" :step="0.01" :min="0" @change='curObjY'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">高度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.height" :step="0.01" :min="1" @change='curObjHeight'></el-input-number>
            </div>
          </div>
          <!-- <div class="single">
            <el-button size="small" @click="equalWidth"> 等宽 </el-button>
            <el-button size="small" @click="equalHeight"> 等高 </el-button>
          </div> -->
          
          <div class="single">
            <div class="font" @click="curObjFontAlign('left')">
              <img src="../../assets/activeduiqi.png" style="background: #ee6363;" v-if="curObj.font.textAlign == 'left'" title="左对齐">
              <img src="../../assets/duiqi.png" alt="" v-else title="左对齐">
            </div>
            <div class="font" @click="curObjFontAlign('center')">
              <img src="../../assets/activejuzhong.png" style="background: #ee6363;" v-if="curObj.font.textAlign == 'center'" title="居中">
              <img src="../../assets/juzhongduiqi.png" alt="" v-else title="居中">
            </div>
            <div class="font" @click="curObjFontAlign('right')">
              <img src="../../assets/activeduiqi_1.png" style="background: #ee6363;" v-if="curObj.font.textAlign == 'right'" title="右对齐">
              <img src="../../assets/duiqi_1.png" alt="" v-else title="右对齐">
            </div>
            <div class="font" @click="curObjFontAlign('justify')">
              <img src="../../assets/activewidth-row.png" style="background: #ee6363;" v-if="curObj.font.textAlign == 'justify'" title="两端对齐">
              <img src="../../assets/width-row.png" alt="" v-else title="两端对齐">
            </div>
            <div class="font" @click="curObjFontWeight">
              <img src="../../assets/activezitiyangshi_jiacu.png" style="background: #ee6363;" v-if="curObj.font.fontWeight == '700'" title="加粗">
              <img src="../../assets/zitiyangshi_jiacu.png" alt="" v-else title="加粗">
            </div>
            <div class="font" @click="curObjFontDecoration">
              <img src="../../assets/activezitiyangshi_xiahuaxian.png" style="background: #ee6363;" v-if="curObj.font.textDecoration == 'underline'" title="下划线">
              <img src="../../assets/zitiyangshi_xiahuaxian.png" alt="" v-else title="下划线">
            </div>
            <div class="font" @click="curObjFontItalics">
              <img src="../../assets/activezitiyangshi_xieti.png" style="background: #ee6363;" v-if="curObj.font.italics == 'italic'" title="斜体">
              <img src="../../assets/zitiyangshi_xieti.png" alt="" v-else title="斜体">
            </div>
          </div>
          <div class="single">
            <el-button size="small" @click="horizontally"> 水平居中 </el-button>
            <el-button size="small" @click="verticalCenter"> 垂直居中 </el-button>
          </div>
          <!-- <div class="single">
            <el-button size="small" @click="topping"> 置顶 </el-button>
            <el-button size="small" @click="bottomSetting"> 置底 </el-button>
          </div> -->
        </div>

        <div class="category" v-if="!curObj.variable">边框属性</div>
        <div class="model-attr" v-if="!curObj.variable">
          <div class="single">
            <span class="label">边框形状：</span>
            <div class="value">
              <el-select v-model="curObj.border.style" placeholder="请选择" size="small" @change="curObjBorderS">
                <el-option
                  v-for="(its, idx) in borderSArr" :key="idx"
                  :label="its.name" :value="its.scription">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="single">
            <span class="label">边框尺寸：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.border.width" :min="0" @change='curObjBorderW'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">边框颜色：</span>
            <div class="value">
              <colorPicker v-model="curObj.border.color" @change="curObjBorderC"/>
            </div>
          </div>
          <div class="single">
            <span class="label">边框弧度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.border.radius" :min="0" @change='curObjBorderR'></el-input-number>
            </div>
          </div>
        </div>

        <div class="category">阴影属性</div>
        <div class="model-attr">
          <div class="single">
            <span class="label">阴影大小：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.boxshadow.size" :min="0" @change='curObjBoxshadow'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">水平距离：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.boxshadow.x" :min="0" @change='curObjBoxshadow'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">模糊程度：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.boxshadow.vague" :min="0" @change='curObjBoxshadow'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">垂直距离：</span>
            <div class="value">
              <el-input-number controls-position="right" size="small" v-model="curObj.boxshadow.y" :min="0" @change='curObjBoxshadow'></el-input-number>
            </div>
          </div>
          <div class="single">
            <span class="label">边框颜色：</span>
            <div class="value">
              <colorPicker v-model="curObj.boxshadow.color" @change="curObjBoxshadow"/>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 背景模板属性 -->
    <el-dialog title="模板属性" :visible.sync="modeAttr" width="600px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='100px'>
      <div class="model-attr">
        <div class="single">
          <span class="label">长度：</span>
          <div class="value">
            <el-input-number size="small" v-model="model.width" :min="5" @change='modelWidth'></el-input-number>
          </div>
        </div>
        <div class="single">
          <span class="label">宽度：</span>
          <div class="value">
            <el-input-number size="small" v-model="model.height" :min="5" @change='modelHeight'></el-input-number>
          </div>
        </div>
        <div class="single">
          <span class="label">正面底图：</span>
          <div class="value">
            <el-button size="small" v-if="!model.image" @click="updateModel"> 点击上传 </el-button>
            <el-button size="small" v-else @click="removeModel"> 去除图片 </el-button>
          </div>
        </div>
        <div class="single">
          <span class="label">正面底色：</span>
          <div class="value">
            <colorPicker v-model="model.color" @change="modelColor"/>
          </div>
        </div>
        <div class="single" v-if="model.plate == 'twoSided'">
          <span class="label">背面底图：</span>
          <div class="value">
            <el-button size="small" v-if="!model.backImage" @click="updateBackModel"> 点击上传 </el-button>
            <el-button size="small" v-else @click="removeBackModel"> 去除图片 </el-button>
          </div>
        </div>
        <div class="single" v-if="model.plate == 'twoSided'">
          <span class="label">背面底色：</span>
          <div class="value">
            <colorPicker v-model="model.backColor" @change="modelBackColor"/>
          </div>
        </div>
        <div class="single">
          <span class="label">显示比例：</span>
          <div class="value">
            <el-input-number size="mini" v-model="zoom" :step='0.1' :min="1" @change="modelZoom"></el-input-number>
          </div>
        </div>
      </div>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 模板 -->
    <el-dialog title="证卡模板" :visible.sync="model_child" width="1060px" center class="model"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <Model ref="Model" @modelEdit='modelEdit' v-if="model_child"></Model>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 文件 -->
    <el-dialog title="证卡文件" :visible.sync="file_child" width="1060px" center class="model"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <cardFile ref="Model" @fileEdit='fileEdit' v-if="file_child"></cardFile>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="cancel" size="small" type="danger" round>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 模板名称 -->
    <el-dialog title="模板名称" :visible.sync="modelTitle_child" width="20%" center class="model" @close='closeDialog("model")'
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <div>
        <el-form status-icon label-width="100px">
          <el-form-item label="文件名称" prop="pass">
            <el-input v-model="modelTitle" placeholder="请输入模板名称"></el-input>
          </el-form-item>
          <el-form-item label="证卡类别" prop="pass">
            <el-select v-model="cardTag" placeholder="请选择">
              <el-option v-for="item in options" :key="item.id"
                :label="item.tagName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="story" size="small" round>确 定</el-button>
        <el-button v-preventReClick @click="closeDialog('model')" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>

    <!-- 文件名称 -->
    <el-dialog title="文件名称" :visible.sync="fileTitle_child" width="20%" center class="model" @close='closeDialog("file")'
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <div>
        <el-form status-icon label-width="100px">
          <el-form-item label="文件名称" prop="pass">
            <el-input v-model="fileTitleMode" placeholder="请输入文件名称"></el-input>
          </el-form-item>
          <el-form-item label="证卡类别" prop="pass">
            <el-select v-model="cardTag" placeholder="请选择">
              <el-option v-for="item in options" :key="item.id"
                :label="item.tagName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="saveFile" size="small" round>确 定</el-button>
        <el-button v-preventReClick @click="closeDialog('file')" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>

    <!-- 文件名称 -->
    <el-dialog title="发送公司" :visible.sync="sendFile" width="20%" center class="model"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <SendFile v-if="sendFile" :backgroupId='modelId' @close='sendFile = false'></SendFile>
      <div class="dialog-btn">
        <el-button v-preventReClick @click="sendFile = false" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapState, mapMutations } from 'vuex'
import Ruler from './Ruler/ruler.vue'
import Model from './Model/model.vue'
import cardFile from './cardFile/cardFile.vue'
import SendFile from './sendFile/sendFile.vue'

import { init, echo, addKeyup } from './plugins/index'
import { family } from './plugins/fontFamil'
import { getAttr } from '@/plugins/plugins'

// 处理颜色 hex转为cmyk
import { convertTo } from '@/plugins/hex_cmyk.js'
let companyId

import JSZip from 'jszip'
// eslint-disable-next-line no-unused-vars
import {saveAs} from 'file-saver'

export default {
  components: {
    Ruler,
    Model,
    cardFile,
    SendFile
  },
  data() {
    let bl = 3.78
    this.curSize = 1

    return {
      switchBool: true,
      disableShade: true,
      meetingName: '',
      meetList: [],

      bl,
      // 鼠标
      mouse: {x: '0', y: '0'},

      // 元素集合
      collection: [],
      model: {
        // width: Math.floor(393 / bl / this.curSize),
        width: (104 / this.curSize).toFixed(0) * 1,
        height: (74 / this.curSize).toFixed(0) * 1,
        color: '#ffffff',
        image: '',
        backImage: '',
        backColor: '#ffffff',
        plate: 'oneSide'
      },
      zoom: 1,

      // 变量
      variArr: {
        image: [
          {name: '无', scription: ''},
          // {name: '二维码', scription: 'qrcode'},
          {name: '人员头像', scription: 'photoFileSaveName'},
        ],
        text: [
          {name: '无', scription: ''},
          {name: '姓名', scription: 'userName'},
          {name: '角色', scription: 'characterId'},
          {name: '手机号码', scription: 'phone'},
          {name: '部门', scription: 'oldDepartmentName'},
          {name: '组别', scription: 'attribute1'},
          {name: '级别', scription: 'ranksId'},
          {name: '分团', scription: 'attribute2'},
        ]
      },
      variObj: {
        photoFileId: '人员头像',
        userName: '姓名',
        characterId: '角色',
        phone: '手机',
        oldDepartmentName: '部门',
        attribute1: '组别',
        ranksId: '级别',
        attribute2: '分团'
      },

      // 边框
      borderSArr: [
        {name: '无', scription: 'none'},
        {name: '直线', scription: 'solid'},
        {name: '虚线', scription: 'dashed'},
        {name: '点状线', scription: 'dotted'},
        {name: '双划线', scription: 'double'},
      ],

      // 字体
      family,

      // 会议信息
      allData: [],

      curObj: {},
      nextObj: {},
      align: '',

      imageAttr: false, // 图片属性开关
      textAttr: false, // 文本属性开关
      modeAttr: false, // 模板属性开关

      modelTitle: '', // 模板名称
      fileTitleMode: '', // 文件名称

      // 子组件开关
      model_child: false,
      file_child: false,
      borderStatus: false,
      modelTitle_child: false,
      fileTitle_child: false,
      sendFile: false,

      fileList: [],
      modelList: [],
      modelEditId: null,

      // 保存背面数据
      twoSided: [],

      // 另存为: saveAs
      saveType: '',

      // 证卡类别
      options: [],
      cardTag: '',

      useModel: false
    }
  },
  computed: {
    ...mapState(['attribute', 'curCardModel', 'modelId', 'fileTitle', 'cardTypeList', 'curCardType'])
  },
  methods: {
    ...mapMutations(['setCurCardModel', 'updateModelId', 'updateFileTitle', 'updateCurCardType', 'updateCardPerson']),
    // 测试 上传文件
    update() {
      let fileDom = document.createElement('input')
      fileDom.setAttribute('type', 'file')
      fileDom.setAttribute('accept', 'image/png,image/jpeg,image/jpg')
      fileDom.setAttribute('multiple', 'multiple')
      fileDom.onchange = e => {
        let files = fileDom.files
        
        const zip = new JSZip()
        
        for(let label in files) {
          if((label * 1)  == label){
            zip.file(files[label].name, files[label]);
          }
        }

        zip.generateAsync({
          type: "blob",
          compression: "DEFLATE", 
          compressionOptions: {
              level: 5 
          }
        }).then(function (content) {
          console.log(content)
          var newFile = blobToFile(content, 'newFile.zip')
          console.log(newFile)
          let href = URL.createObjectURL(newFile);//获取url
          let down = document.createElement('a')
          down.setAttribute('href', href)
          down.click();
          // saveAs(content, 'newZip.zip');
        });
        
      }

      fileDom.click()
    
      function blobToFile(blob, fileName){
        blob.lastModifiedDate =new Date();
        blob.name = fileName;
        return blob;
      }
    },
    handleClose(done) {
      done()
      this.imageAttr = false

    },
    // 定稿
    finalize() {
      if(this.useModel) return 
      if(!this.modelId) {
        this.$message.error('请先保存文件！')
        return 
      }
      if(this.curCardModel.finalize == 1) {
        this.$message.error('此文件已定稿，无需再次点击！')
        return
      }
      console.log(this.curCardModel)
      this.$confirm('是否将此文件定稿?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.API.finalize(this.modelId))
          .then((res) => {
            // let res = JSON.parse(body)
            console.log(res)
            if(res.code == '000') {
              this.$message.success('已定稿')
              this.disableShade = false
              this.setCurCardModel({
                cardTag: this.curCardModel.cardTag,
                finalize: 1,
                modelName: this.curCardModel.modelName,
                id: this.curCardModel.id,
                collection: this.curCardModel.collection,
                model: this.curCardModel.model
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      })

    },
    // 发送文件到客户方
    sendCard() {
      if(!this.modelId) {
        this.$message.error('请先保存文件！')
        return 
      }

      this.sendFile = true
    },

    // 新建文件
    submit() {
      this.$confirm('是否清空当前文件，重新制作?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.do_submit()
      })
    },
    do_submit() {
      this.updateModelId(null)
      this.updateFileTitle('')
      this.updateCardPerson([])

      this.fileEdit({
        cardTag: '',
        collection: [],
        model: {
          width: (104 / this.curSize).toFixed(0) * 1,
          height: (74 / this.curSize).toFixed(0) * 1,
          color: '#ffffff',
          image: '',
          backImage: '',
          backColor: '#ffffff',
          plate: 'oneSide'
        },
      })
    },

    closeDialog(val) {
      if(val == 'file'){
        this.fileTitleMode = ''
        this.saveType = ''
        // this.updateFileTitle('')
        this.fileTitle_child = false
      } else if(val == 'model') {
        this.modelTitle = ''
        this.modelTitle_child = false
      }
    },

    modelBg() {
      this.modeAttr = true
    },
    // 去除元素图片
    removeImg() {
      this.curObj.image = ''
      $('#'+this.curObj.id).css('background-image', `url()`)
      $('#'+this.curObj.id).find('.cte').html(this.curObj.eleType)
    },
    // 元素图片
    updateImg() {
      // 创建 file 节点
      let file = document.createElement('input'), that = this
      file.setAttribute('accept', 'image/png,image/jpg,image/jpeg')
      file.type = 'file'

      file.onchange = function(e) {
        let files = this.files[0]

        that.fileUpload(files, 'Card', res => {
          if(res.code == '000') {
            that.$message.success(res.msg)
            console.log(companyId)
            that.curObj.image = `${companyId}card/Card/${res.data.saveFileName}`
            $('#'+that.curObj.id).find('.cte').html('')

            let s = setInterval(() => {
              if(that.CheckImgExists(that.API.echoImage(res.data.saveFileName))){
                clearInterval(s)
                $('#'+that.curObj.id).css('background-image', `url(${that.API.echoImage(res.data.saveFileName)})`)
              }
            }, 1000)

          } else {
            that.$message.error(res.msg)
          }
          file.value = ''
        })
      }
      file.click()
    },
    // 图片是否有效
    CheckImgExists(imgurl) {
      var ImgObj = new Image(); //判断图片是否存在  
      ImgObj.src = imgurl;  
      //存在图片
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {  
        return true;
      } else {
        return false;
      } 
    },
    // 去除模板背景 
    removeModel() {
      this.model.image = ''
      $('.model-bg').css('background-image', `url()`)
    },
    // 模板背景
    updateModel() {
      // 创建 file 节点
      let file = document.createElement('input'), that = this
      file.setAttribute('accept', 'image/png,image/jpg,image/jpeg')
      file.type = 'file'
      file.onchange = function(e) {
        console.log(this.files)
        let files = this.files[0]

        that.fileUpload(files, 'Card', res => {
          if(res.code == '000') {
            that.$message.success(res.msg)
            that.model.image = `${companyId}card/Card/${res.data.saveFileName}`

            let s = setInterval(() => {
              if(that.CheckImgExists(that.API.echoImage(res.data.saveFileName))){
                clearInterval(s)
                $('.model-bg').css('background-image', `url(${that.API.echoImage(res.data.saveFileName)})`)
              }
            }, 1000)

          } else {
            that.$message.error(res.msg)
          }
          file.value = ''
        })
      }
      file.click()
    },
    // 去除模板背面背景 
    removeBackModel() {
      this.model.backImage = ''
      $('.back-model-bg').css('background-image', `url()`)
    },
    // 模板背面背景
    updateBackModel() {
      // 创建 file 节点
      let file = document.createElement('input'), that = this
      file.setAttribute('accept', 'image/png,image/jpg,image/jpeg')
      file.type = 'file'
      file.onchange = function(e) {
        console.log(this.files)
        let files = this.files[0]

        that.fileUpload(files, 'Card', res => {
          if(res.code == '000') {
            that.$message.success(res.msg)
            that.model.backImage = `${companyId}card/Card/${res.data.saveFileName}`

            let s = setInterval(() => {
              if(that.CheckImgExists(that.API.echoImage(res.data.saveFileName))){
                clearInterval(s)
                $('.back-model-bg').css('background-image', `url(${that.API.echoImage(res.data.saveFileName)})`)
              }
            }, 1000)

          } else {
            that.$message.error(res.msg)
          }
          file.value = ''
        })
      }
      file.click()
    },
    // 修改模板
    edit(row) {
      if(this.collection.length != 0){
        this.$confirm('是否替换之前的模板?', '提示', {  
          closeOnPressEscape: false,
          closeOnClickModal: false,
          cancelButtonClass: 'btn_custom_cancel',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.collection.filter(its => $('.model-bg').find("#"+its.id).remove())

          this.collection = JSON.parse(row.collection)
          this.model = JSON.parse(row.model)
          
          echo({collection: this.collection, model: this.model}, this)

          this.model_child = false
        })
      } else {
        console.log(row)
        this.collection = JSON.parse(row.collection)
        this.model = JSON.parse(row.model)
        
        echo({collection: this.collection, model: this.model}, this)

        this.model_child = false
      }
    },

    // 保存模板
    story() {
      if(this.curCardModel.finalize) return 

      if(!this.modelTitle || !this.cardTag){ 
        this.modelTitle_child = true
        return 
      } else {
        if(this.cardTag == '') {
          this.$message.error('请选择证卡类别')
          return 
        }
        if(!this.modelEditId && this.modelList.includes(this.modelTitle.trim())) {
          this.$message.error('模板名称已重复！')
          return 
        }
        this.modelTitle_child = false
      }

      // localStorage.setItem('card', JSON.stringify({collection: this.collection, model: this.model}))
      // return 
      let background = {...this.model},
      elements = JSON.parse(JSON.stringify(this.collection))
      background.color = convertTo('hex', 'rgb', background.color)
      background.backColor = convertTo('hex', 'rgb', background.backColor)
      background.modelName = this.modelTitle
      background.id = ''
      background.cardTag = this.cardTag
      // background.id = this.modelEditId

      for(let i = 0; i < elements.length; i++) {
        // elements[i].color = elements[i].font.color
        if(elements[i].eleType == 'text') {
          elements[i].color = convertTo('hex', 'rgb', elements[i].font.color)
          elements[i].fontSize = elements[i].font.size
          elements[i].center = elements[i].font.textAlign
          elements[i].fonts = elements[i].font.fonts

        } else {
          elements[i].radio = elements[i].border.radius

          delete elements[i].border
        }
        delete elements[i].font
        delete elements[i].id
        delete elements[i].border
        delete elements[i].boxshadow
        // delete elements[i].eleType
        // delete elements[i].cte
      }

      delete background.Cover

      let obja = { background, elements}

      let inter = this.modelEditId ? 'updateModel' : 'saveModel'
      // 模板只有添加 没有修改
      this.$http.post(this.API.generateCard(inter), obja)
        .then((res) => {
          // let res = JSON.parse(body)
          console.log(res)
          if(res.code == '000') {
            this.$message.success('保存成功!')
            this.modelEditId = res.id
            this.modelTitle = ''
            // this.modelTitle = background.modelName
          } else {
            this.$message.error(res.msg)
          }
        })
    },  

    // 另存为
    saveAs() {  
      this.fileTitle_child = true
      this.saveType = 'saveAs'
    },
    // 保存文件 saveAs 另存为
    saveFile(type) {
      console.log(this.useModel)
      if(this.saveType != 'saveAs' && (this.curCardModel.finalize || this.useModel)) return 
      if((this.modelId && !this.fileTitle) || !this.cardTag){ 
        this.fileTitle_child = true
        return 
      } else {
        if(this.cardTag == '') {
          this.$message.error('请选择证卡类别')
          return 
        }

        if(!this.modelId && this.fileList.includes(this.fileTitleMode.trim())) {
          this.$message.error('文件名称已重复！')
          return 
        }
        this.fileTitle_child = false
      }

      let background = {...this.model},
      elements = JSON.parse(JSON.stringify(this.collection))

      console.log(background.color)
      background.color = convertTo('hex', 'rgb', background.color)
      background.backColor = convertTo('hex', 'rgb', background.backColor)
      background.modelName = this.fileTitleMode || this.fileTitle
      background.cardTag = this.cardTag
      if(this.saveType == 'saveAs') {
        background.id = ''
      } else {
        background.id = this.modelId
      }

      for(let i = 0; i < elements.length; i++) {
        // elements[i].color = elements[i].font.color
        if(elements[i].eleType == 'text') {
          console.log(elements[i].font.color)
          elements[i].color = convertTo('hex', 'rgb', elements[i].font.color)
          elements[i].fontSize = elements[i].font.size
          elements[i].center = elements[i].font.textAlign
          elements[i].fonts = elements[i].font.fonts

        } else {
          elements[i].radio = elements[i].border.radius

          delete elements[i].border
        }
        delete elements[i].font
        delete elements[i].id
        delete elements[i].border
        delete elements[i].boxshadow
      }

      delete background.Cover

      let obja = { background, elements}
      console.log(obja,this.model)
      // let inter = this.modelId ? 'updateModel' : 'saveModel'
      // 模板只有添加 没有修改
      this.$http.post(this.API.generateCard('saveElementAndUser'), obja)
        .then((res) => {
          // let res = JSON.parse(body)
          console.log(res)
          if(res.code == '000') {
            this.$message.success('保存成功!')
            this.updateModelId(res.data)
            this.updateFileTitle(background.modelName || this.fileTitle)
            this.updateCardPerson([])
            this.saveType = ''
            // 保存数据 vuex
            this.setCurCardModel({
              cardTag: this.cardTag,
              finalize: 0,
              modelName: this.fileTitle,
              id: res.data,
              collection: this.collection,
              model: this.model
            })
            this.fileTitle_child = false
          } else {
            this.$message.error(res.msg)
          }
        })
      
    },
    changeFile(val) {
      this.updateFileTitle(val)
    },

    // 回显
    echo() {
      $('.record').remove()
    },

    // 元素失去焦点
    eleBlurSelect() {
      this.removeSelect()
      this.curObj = {}
    },
    // 选中
    selectCur(row) {
      if(!this.disableShade) return 
      this.removeSelect()
      $('#' + row.id).addClass('select')
      this.curObj = row
    },
    // 去除其它元素选中状态, 解绑所有事件
    removeSelect() {
     $('.template').removeClass("select")
    },

    // 选择模板 - 确定按钮
    determine() {
      this.model_child = false
    },
    cancel() {
      this.imageAttr = false
      this.textAttr = false
      this.modeAttr = false
      this.model_child = false
      this.file_child = false
    },
    
    // 元素列表双击
    dblclick(row) {
      if(!this.disableShade) return 

      if(row.eleType == 'image') vue.imageAttr = true
      else vue.textAttr = true
    },

    // 元素左对齐
    alignLeft() {
      this.nextObj = this.curObj
      this.align = 'left'
    },
    // 元素右对齐
    alignRight() {
      this.nextObj = this.curObj
      this.align = 'right'
    },
    // 元素上对齐
    alignTop() {
      this.nextObj = this.curObj
      this.align = 'top'
    },
    // 元素下对齐
    alignBottom() {
      this.nextObj = this.curObj
      this.align = 'bottom'
    },
    // 显示边框
    showBorder() {
      if(this.collection.length == 0) return 

      if(this.borderStatus) {
        this.borderStatus = false
        this.removeSelect()
        this.collection.filter(its => {
          if(its.border.style == 'none'){
            $('#'+its.id).css('box-shadow', 'none')
            $('#'+its.id).addClass('not-border')
          }
        })
      } else {
        this.borderStatus = true
        this.collection.filter(its => {
          if(its.border.style == 'none'){
            $('#'+its.id).removeClass('not-border')
            $('#'+its.id).css('box-shadow', '0 0 1px 1px #333')
          }
        })
      }
    },

    // model
    modelWidth(v) {
      $('.model-bg').css('width', v * this.bl+'px')
    },
    modelHeight(v) {
      $('.model-bg').css('height', v * this.bl+'px')
    },
    modelColor(v) {
      // convertTo('hex', 'cmyk', v)
      $('.model-bg').css('background-color', v)
    },
    modelBackColor(v) {
      $('.back-model-bg').css('background-color', v)
    },
    modelZoom(v) {
      $('.rules ').css({
        // 'transform': `scale(${v})`,
        'zoom': v,
        'transform-origin': '0% 0%'
      })
    },

    // text
    curObjCte(v) {
      $('#'+this.curObj.id).find('.cte').html(v);
    },
    curObjFamily(v) {
      $('#'+this.curObj.id).find('.cte').css('font-family', v);
      this.curObj.font.fonts = this.family.filter(its => its.value == v && its)[0].params || 'yahei'
    },
    curObjFontSize(v) {
      $('#'+this.curObj.id).find('.cte').css('font-size', v+'px');
    },
    curObjFontSpacing(v) {
      $('#'+this.curObj.id).find('.cte').css('letter-spacing', v+'px')
    },
    curObjFontLineHeight(v) {
      $('#'+this.curObj.id).find('.cte').css('line-height', v * this.bl+'px')
    },
    curObjFontLineColor(v) {
      $('#'+this.curObj.id).find('.cte').css('color', v)
    },
    curObjFontAlign(v) {
      this.curObj.font.textAlign = v
      if(v == 'justify') {
        $('#'+this.curObj.id).find('.cte').css({'text-align': v, 'text-align-last': v})
      } else {
        $('#'+this.curObj.id).find('.cte').css({'text-align': v, 'text-align-last': 'inherit'})
      }
    },
    curObjFontWeight() {
      this.curObj.font.fontWeight = this.curObj.font.fontWeight == '700' ? '400' : '700'
      $('#'+this.curObj.id).find('.cte').css('font-weight', this.curObj.font.fontWeight)
    },
    curObjFontDecoration() {
      this.curObj.font.textDecoration = this.curObj.font.textDecoration == 'underline' ? 'none' : 'underline'
      $('#'+this.curObj.id).find('.cte').css('text-decoration', this.curObj.font.textDecoration)
    },
    curObjFontItalics() {
      this.curObj.font.italics = this.curObj.font.italics == 'italic' ? 'normal' : 'italic'
      $('#'+this.curObj.id).find('.cte').css('font-style', this.curObj.font.italics)
    },

    // image
    curObjX(v) {
      $('#'+this.curObj.id).css('left', v * this.bl + 'px');
    },
    curObjY(v) {
      $('#'+this.curObj.id).css('top', v * this.bl + 'px');
    },
    curObjWidth(v) {
      $('#'+this.curObj.id).css('width', v * this.bl + 'px');
    },
    curObjHeight(v) {
      $('#'+this.curObj.id).css('height', v * this.bl + 'px');
    },
    curObjOpacity(v) {
      $('#'+this.curObj.id).css('opacity', v / 100);
    },
    curObjVari(v) {
      this.curObj.variable = v
      if(v) {
        $('#'+this.curObj.id).css('border', `1px solid red`);
        this.curObj.border.style = 'none'
      } else $('#'+this.curObj.id).css('border', `none`)
      if(this.curObj.eleType == 'text') {
        this.curObj.conten = this.variObj[v]
        $('#'+this.curObj.id).find('.cte').html(this.curObj.conten)
      }
    },
    equalWidth() {
      let mattix = $('#'+this.curObj.id).css('transform'),
      s = mattix.split(','),
      nodeX = Number(s[4]),
		  nodeY = Number(s[5].replace(')', ''))

      this.curObj.width = this.model.width
      this.curObj.x = 0
      $('#'+this.curObj.id).css({
        'transform': 'translate( 0px,' + nodeY + 'px)',
        'width': this.model.width * this.bl+'px'
      });
    },
    equalHeight() {
      let mattix = $('#'+this.curObj.id).css('transform'),
      s = mattix.split(','),
      nodeX = Number(s[4]),
		  nodeY = Number(s[5].replace(')', ''))

      this.curObj.height = this.model.height
      this.curObj.y = 0
      $('#'+this.curObj.id).css({
        'transform': 'translate(' + nodeX + 'px, 0px)',
        'height': this.model.height * this.bl+'px'
      });
    },
    horizontally() {
      let totalW = this.model.width / 2,
      eleW = this.curObj.width / 2
      let mattix = $('#'+this.curObj.id).css('transform'),
      s = mattix.split(','),
      nodeY = Number(s[5].replace(')', ''))

      this.curObj.x = Math.floor(totalW - eleW)
      $('#'+this.curObj.id).css('transform', 'translate(' + Math.floor(totalW - eleW) * this.bl + 'px,' + nodeY + 'px)');
    },
    verticalCenter() {
      let totalH = this.model.height / 2,
      eleH = this.curObj.height / 2
      let mattix = $('#'+this.curObj.id).css('transform'),
      s = mattix.split(','),
      nodeX = Number(s[4])

      this.curObj.y = Math.floor(totalH - eleH)
      $('#'+this.curObj.id).css('transform', 'translate(' + nodeX + 'px,' + Math.floor(totalH - eleH) * this.bl + 'px)');
    },
    curObjBorderS(v) {
      $('#'+this.curObj.id).css('border-style', v);
      if(v != 'none' && this.curObj.border.width == 0) {
        this.curObj.border.width = 1
        this.curObjBorderW(1)
      }
    },
    curObjBorderW(v) {
      $('#'+this.curObj.id).css('border-width', v + 'px');
    },
    curObjBorderC(v) {
      $('#'+this.curObj.id).css('border-color', v)
    },
    curObjBorderR(v) {
      $('#'+this.curObj.id).css('border-radius', v + 'px')
    },
    curObjBoxshadow() {
      let boxshadow = this.curObj.boxshadow,
      v = `${boxshadow.x}px ${boxshadow.y}px ${boxshadow.vague}px ${boxshadow.size}px ${boxshadow.color}`
      $('#'+this.curObj.id).css('box-shadow', v)
    },
    topping() {
      let obj = this.curObj, collection = this.collection
      if(!obj.id) return 
      
      collection.filter(its => {
        if(its.id != obj.id && its.zIndex > obj.zIndex){
          its.zIndex --
          $('#'+its.id).css('z-index', its.zIndex)
        }
      })
      obj.zIndex = collection.length
      $('#'+obj.id).css('z-index', obj.zIndex)
    },
    bottomSetting() {
      let obj = this.curObj, collection = this.collection
      if(!obj.id) return 

      collection.filter(its => {
        if(its.id != obj.id && its.zIndex < obj.zIndex){
          its.zIndex ++
          $('#'+its.id).css('z-index', its.zIndex)
        }
      })
      obj.zIndex = 1
      $('#'+obj.id).css('z-index', obj.zIndex)
    },

    // 获取会议列表
    getMeetList() {
      this.$http.post(this.API.meetings).then(
        (res) => {
        // let res = JSON.parse(body)
          if(res.code == '000') {
            this.meetList = res.data
          }
        }
      )
    },

    // 获取人员
    getPerson(id) {
      let url = `https://service-ij14wquc-1305256445.gz.apigw.tencentcs.com/release/findByMeetingIdAndPage?meetingId=${id}&confereeGroupId=${id}&pageNum=1&searchKey=&pageSize=10000&statuslCode=&report=2&externalCode=2&add=`
      let obj = {
        contanUserIdArr: [],
        ifContanUserIdArr: false,
        queryConditionArr: []
      }
      this.$http.post(url, obj).then(
        (res) => {
        // let res = JSON.parse(body)
          if(res.code == '000') {
            this.personIds = res.data
          }
        }
      )
    },
    // 选择文件
    fileEdit(row) {
      if(this.curCardModel.id && this.curCardModel.id == row.id) {
        this.$message.info('已选择该文件!')
        return 
      }
      if(row.finalize == 1) this.disableShade = false
      else this.disableShade = true

      this.borderStatus = false
      
      // 关闭弹窗
      this.file_child = false
      this.modelTitle = ''
      this.modelEditId = null

      // 处理数据
      let item = JSON.parse(JSON.stringify(row))
      console.log(row)
      let {model, collection} = item
      this.updateCardPerson([])

      this.cardTag = row.cardTag
      this.model = model
      this.collection = collection
      setTimeout(() => echo({collection, model}, this), 100)

      // 保存数据 vuex
      this.setCurCardModel(item)

      document.querySelector('.right-body').focus()
    },

    // 选择模板
    modelEdit(row) {
      // 重置模板名称
      // this.modelTitle = row.modelName
      this.useModel = true

      // 关闭弹窗
      this.model_child = false

      // 处理数据
      this.modelEditId = row.id
      let item = JSON.parse(JSON.stringify(row))
      console.log(row)
      let {model, collection} = item

      this.cardTag = row.cardTag
      this.model = model
      this.collection = collection
      setTimeout(() => echo({collection, model}, this), 100)
      this.updateCardPerson([])


      // 保存数据 vuex
      this.setCurCardModel(item)

      document.querySelector('.right-body').focus()
    },
    // 获取公司信息
    getCustomer() {
      let loginInfo = JSON.parse(localStorage.getItem('loginInfo'))

      let infoTime = setInterval(() => {
        loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
        if(loginInfo.id) {
          this.loginInfo = loginInfo
          
          companyId = loginInfo.companyId
          clearInterval(infoTime)
        }
      }, 100)
    },
  },
  
  created() {
    let token = localStorage.getItem('tokenCard') || null

    if(!token) this.$router.push('/login')
    else {
      this.$http.post(this.API.selectModel(1, 9999, 'selectElementAndUser'), {})
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000') {
            this.fileList = res.data.map(its => its.modelName)

          }
        })
      this.$http.post(this.API.selectModel(1, 9999, 'selectModel'), {})
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000') {
            this.modelList = res.data.map(its => its.modelName)
          }
        })
    }
    
    localStorage.setItem('rule', JSON.stringify(this.model))
  },
  mounted() {
    // 初始化方法
    init(this)
    this.getCustomer()

    addKeyup(this)
    let {model, collection} = this.curCardModel
    if(model) {
      this.model = model
      this.collection = collection
      this.cardTag = this.curCardType
      echo({collection, model}, this)
    }

    if(this.cardTypeList.length > 0) {
      this.options = this.cardTypeList
    }

  },
  watch: {
    'modelTitle': function(val) {
      console.log('modelTitle', val)
    },
    'fileTitle_child': function(val) {
      // if(val) this.fileTitleMode = this.fileTitle
    },
    'fileTitle': function(val) {
      console.log('fileTitle', val)
      if(val) this.useModel = false
    },
    'model.plate': function(val) {
      if(val == 'oneSide') {
        this.twoSided = []
        this.collection = this.collection.filter(its => {
          if(its.side == 'twoSided') this.twoSided.push(its)
          else return its
        })
      } 
      this.model.plate = val

      if(val == 'twoSided') {
        this.collection.push(...this.twoSided)
        setTimeout(() => init(this), 100)
        setTimeout(() => echo({collection: this.collection, model: this.model}, this), 100)
      }
    },
    'cardTypeList': function(val) {
      this.options = val
    },
    cardTag: function(val) {
      // 全局储存当前类别
      if(val) this.updateCurCardType(val)
    }
  },
}
</script>

<style scoped lang='less'>
.make {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .element {
    width: 56px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    padding-top: 28px;
    box-sizing: border-box;
    margin-right: 10px;

    img {
      margin-bottom: 10px;
    }

    & > div{
      &:hover {
        img {
          opacity: .8;
        }

        color: #409eff;
      }
    }

    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      
      div {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
      }
    }

    .card-side {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      & > * {
        margin: 0;
      }

      .switchBool {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: not-allowed;
          z-index: 999;
        }
      }

      .lock {
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 22px;
        font-weight: 700;
        cursor: pointer;

        i {
          margin-right: 8px;
        }

        &:hover {
          color: #409eff;
        }
      }
    }
  }

  .model-bg, .back-model-bg {
    width: 500px;
    height: 281px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 10px;
    position: relative;

    .template {
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      border: 1px dashed transparent;
      position: absolute;
      min-width: 40px;
      min-height: 20px;
      display: none;
      outline: none;
      // transform: translate(20px, 20px);
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .cte {
        width: 100%;
        height: 100%;
        // word-wrap: break-word;
        // word-break: normal;
        overflow: hidden;
      }
    }

    .not-border {
      border: none !important;
    }

    .select {
      // border-color: #333;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px dashed #333;
        box-sizing: border-box;
      }

      .rightBottom {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #333;
        border-radius: 50%;
        right: -6px;
        bottom: -6px;
        cursor: se-resize;
      }
    }
  }
  
  .trajectory {
    position: absolute;
    top: 0;
    width: 100px;
    left: 100%;
  }

  .ele-list {
    width: 200px;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    z-index: 2;

    .fixed {
      width: 100%;
      padding: 20px 5px;
      box-sizing: border-box;
      background-color: #eee;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 15px;

      .btn {
        width: 80px;
        height: 30px;
        border-radius: 50px;
        margin-bottom: 10px;
        line-height: 28px;
        text-align: center;
        transition: all .2s;
        border: 1px solid #ccc;
        box-sizing: border-box;
        color: #333;
        font-size: 12px;
        background-color: #fff;
        cursor: pointer;

        &:hover {
          color: #409EFF;
          border-color: #c6e2ff;
          background-color: #ecf5ff;
        }
      }

      .not-allowed {
        cursor: not-allowed;
        background-color: #f5f5f5;
        opacity: .8;

        &:hover {
          color: #333;
          border-color: #ccc;
          background-color: #f5f5f5;
        }
      }

      .special {
        color: #409EFF;
        border-color: #c6e2ff;
        background-color: #ecf5ff;

        &:hover {
          background-color: rgb(210, 231, 255);
        }
      }

      .margin-top {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    .single {
      width: 60px;
      height: 36px;
      padding: 0 2px;
      border: 1px solid #333;
      box-sizing: border-box;
      margin: 0 10px;
      margin-bottom: 15px;
      font-size: 12px;
      overflow: hidden;
      text-align: center;
      border-radius: 3px;
    }

    .varName {
      border-color: crimson;
    }

    .select {
      box-shadow: 3px 3px 5px 1px #73777c;
    }
  }

  .model-attr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: hidden;
    
    .single {
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 10px;

      .font img {
        width: 18px;
        height: 18px;
      }

      .label {
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }

      .value {
        width: calc(100% - 100px);
      }
    }
  }

  .category {
    width: 100%;
    background-color: #9bb5d3;
    color: #fff;
    padding: 3px 10px;
    box-sizing: border-box;
    letter-spacing: 3px;
    margin-bottom: 10px;
  }
}
</style>

<style lang="less">
.make {
  .model .el-dialog {
    margin: 80px  auto!important;
  }

  .ele-list {
    .el-button+.el-button {
      margin-left: 0;
    }
  
    .el-button {
      margin-bottom: 10px;
    }
  }

}
</style>