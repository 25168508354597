<template>
  <div class="send-file">
    <el-autocomplete v-model="state" style="width: 100%" :fetch-suggestions="querySearchAsync"
     placeholder="请输入内容"  @select="handleSelect"></el-autocomplete>
  </div>
</template>

<script>
export default {
  props: ['backgroupId'],
  data() {
    return {
      restaurants: [],
      state: ''
    }
  },
  methods: {
    loadAll() {
      return [
        { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
      ]
    },
    querySearchAsync(queryString, cb) {
      // var restaurants = this.restaurants;
      // var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      
      if(!queryString.trim()) {
        cb([]);
        return 
      }

      this.$http.post(this.API.searchCompany(queryString))
        .then((res) => {
          // let res = JSON.parse(body)
          console.log(res)
          if(res.code == '000') {
            res.data.filter(its => {
              its.value = its.companyName
            })

            cb(res.data)
          } else cb([])
        })
      
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item);
      this.$http.post(this.API.sendOut(this.backgroupId, item.id))
        .then((res) => {
          // let res = JSON.parse(body)
          console.log(res)
          if(res.code == '000') {
            this.$message.success('发送成功!')
            this.$emit('close')
          } else {
            this.$message.error(res.msg)
          }
        })
      
    }
  },
  mounted() {
    this.restaurants = this.loadAll();
  }
}
</script>

<style lang="less" scoped>
.send-file {
  // width: 100vw;
  // height: 100vh;
  // background-color: rgba(0, 0, 0, 0.4);
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 999999;
}
</style>