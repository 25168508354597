import $ from 'jquery'

const imageObj = {
  id: '', // 唯一标识
  x: 0,
  y: 0,
  width: 25, // 元素宽度
  height: 35, // 元素高度
  eleType: '', // 元素类型  image, text
  opacity: 100, // 透明度
  image: '', // 图片
  variable: '', // 变量
  zIndex: 1, // 层级
  border: { // 边框, 形状 宽度 弧度 颜色
    style: 'none',
    width: 0,
    radius: 0,
    color: '', 
  },
  boxshadow: { // 阴影 - 大小 模糊程度 水平距离 垂直距离 颜色
    size: 0,
    vague: 0,
    x: 0,
    y: 0,
    color: ''
  },
  font: { // 字体 类型 大小 间距
    family: '',
    size: 16,
    spacing: 0, 
  }
},
textObj = {
  id: '', // 唯一标识
  x: 0,
  y: 0,
  width: 31, // 元素宽度
  height: 10, // 元素高度
  eleType: '', // 元素类型  image, text
  opacity: 100, // 透明度
  variable: '', // 变量
  zIndex: 1, // 层级
  border: { // 边框, 形状 宽度 弧度 颜色
    style: 'none',
    width: 0,
    radius: 0,
    color: '', 
  },
  boxshadow: { // 阴影 - 大小 模糊程度 水平距离 垂直距离 颜色
    size: 0,
    vague: 0,
    x: 0,
    y: 0,
    color: ''
  },
  conten: 'text', // 内容
  font: { // 字体 类型 大小 间距 行高 颜色 对齐方式 粗细 下划线 斜体
    family: 'Microsoft YaHei',
    fonts: 'yahei',
    size: 20,
    spacing: 0, 
    // lineHeight: 5,
    color: '#000000',
    textAlign: 'left',
    fontWeight: '400',
    textDecoration: 'none',
    italics: 'normal'
  }
}
let url = `https://backgroupcard-1305256445.cos.ap-guangzhou.myqcloud.com/`
export function init(vue) {
  window.vue = vue
  // 模板背景
  // $('.model-bg').on('mousemove', (e) => {
  //   console.log(e.layerX, e.layerY)
  //   vue.mouse = {x: e.layerX, y: e.layerY}
  // })

  // 绑定拖动事件
  let draggable = ['#image', '#text']
  // 拖动区域dom
  let region = ['.model-bg', '.back-model-bg']

  for(let i = 0; i < draggable.length; i++){
    let child = document.querySelector(draggable[i])
    child.ondragstart = function(event){
      var e = event || window.event
      e.dataTransfer.setData('text', e.target.id);
    }
  }

  for(let i = 0; i < region.length; i++){
    let child = document.querySelector(region[i])
    if(!child) continue;
    
    child.ondragover = function(event){
      // console.log(child)
      var event = event || window.event;
      event.preventDefault()
    }
    child.ondrop = function(event){
      var e = event || window.event
      var data = e.dataTransfer.getData('text');

      // console.log({initX: e.layerX, initY: e.layerY})
      console.log(e)
      // 创建元素
      createElement(child, data, {initX: e.offsetX, initY: e.offsetY})
    }
  }
}

// 创建元素
function createElement (plate, type, position){
  if(type == 'image')
    vue.curObj = JSON.parse(JSON.stringify(imageObj))
  else
    vue.curObj = JSON.parse(JSON.stringify(textObj))
  vue.curObj.zIndex = vue.collection.length + 1

  console.dir(plate.className)
  if(plate.className.indexOf('back-model-bg') > -1) vue.curObj.side = 'twoSided'
  else vue.curObj.side = 'oneSide'
   
  // 获取最初落地坐标
  let initX = Math.floor((position.initX - $('.mold').width() / 2) / vue.zoom),
   initY = Math.floor((position.initY - $('.mold').height() / 2) / vue.zoom)

  // 去除其它元素选中状态
  vue.removeSelect()
  console.log(initX, initY)
  // 克隆
  let node = $(plate).find('.mold').clone(true).attr('class', 'template select').css({
    'display': 'block',
    'width': vue.curObj.width * vue.bl + 'px',
    'height': vue.curObj.height * vue.bl + 'px',
    // 'transform': 'translate(' + (initX - plate.offsetLeft) + 'px,' + (initY - plate.offsetTop) + 'px)',
    left: (initX) + 'px', 
    top:  (initY) + 'px'
  }).addClass('record'), id = uuid()
  node.attr('id', id)
  vue.curObj.id = id
  vue.curObj.eleType = type
  vue.curObj.x = Math.floor((initX - plate.offsetLeft) / vue.bl)
  vue.curObj.y = Math.floor((initY  - plate.offsetTop) / vue.bl)

  // 添加事件
  addEvent(node, plate)

  // 增加默认文本
  $(node).find('.cte').html(type)

  // 样式回显
  styleEcho(node, vue.curObj)

  // 存入集合
  vue.collection.push(vue.curObj)

  $(plate).append(node)
}

// node添加事件
function addEvent(node, plate) {
  // 双击
  node.on('dblclick', e => {
    e.preventDefault();
    e.stopPropagation();
    if(vue.curObj.eleType == 'image') vue.imageAttr = true
    else vue.textAttr = true
  })
  
  node.on('mousedown', ev => {
    ev.preventDefault();
    ev.stopPropagation();

    if(!node.hasClass("select")){
      // 去除其它元素选中状态
      vue.removeSelect()
      let id = node.attr('id'), row = vue.collection.filter(its => id === its.id && its)[0]
      $('#' + id).addClass('select')
      vue.curObj = row
      // 对齐
      if(vue.align) {
        let nodeX = $('#'+vue.nextObj.id).css('left'),
        nodeY = $('#'+vue.nextObj.id).css('top')

        switch(vue.align) {
          case 'left': 
            vue.nextObj.x = vue.curObj.x
            $('#' + vue.nextObj.id).css('left', vue.nextObj.x * vue.bl + 'px')
              ;break
          case 'right': 
            vue.nextObj.x = (vue.curObj.x + vue.curObj.width) - vue.nextObj.width
            $('#' + vue.nextObj.id).css('left', vue.nextObj.x * vue.bl + 'px')
              ;break
          case 'top': 
            vue.nextObj.y = vue.curObj.y
            $('#' + vue.nextObj.id).css('top', vue.nextObj.y * vue.bl + 'px')
              ;break
          case 'bottom': 
            vue.nextObj.y = (vue.curObj.y + vue.curObj.height) - vue.nextObj.height
            $('#' + vue.nextObj.id).css('top', vue.nextObj.y * vue.bl + 'px')
              ;break
        }
        vue.align = ''
      }
    }
    
    let {top, left} = node.position()
    let x = ev.pageX, y = ev.pageY
    
    document.body.onmousemove = function(e) {
      let curX = Math.floor((left + (e.pageX - x) / vue.zoom)), 
      curY = Math.floor((top + (e.pageY - y) / vue.zoom))
      
      node.css({ left: curX + 'px', top: curY + 'px'});

      vue.curObj.x = (curX / vue.bl).toFixed(2) * 1
      vue.curObj.y = (curY / vue.bl).toFixed(2) * 1
    }
  })

  document.body.onmouseup = e => {
    e.preventDefault();
    e.stopPropagation();
    document.body.onmousemove = null
  }

  node.on('mouseup', e => {
    $('.rules')[0].onmousemove = null
    node.css('z-index', vue.curObj.zIndex)
  })
  $('.rules').on('mouseleave', e => {
    $('.rules')[0].onmousemove = null
    node.css('z-index', vue.curObj.zIndex)
  })

  // 右下拖动
  let rightBottom = $(node).find('.rightBottom')
  rightBottom.on('mousedown', ev => {
    ev.preventDefault();
    ev.stopPropagation();
    let x = ev.pageX, y = ev.pageY
    let width = $(node).width(), height = $(node).height()
    
    document.body.onmousemove = function(e) {
      let resW = (e.pageX - x) / vue.zoom + width, resH = (e.pageY - y) / vue.zoom + height
      node.css({width: `${resW}px`, height: `${resH}px`});

      vue.curObj.width = (resW / vue.bl).toFixed(2) * 1
      vue.curObj.height = (resH / vue.bl).toFixed(2) * 1
    }
  })
}

// 唯一id
function uuid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

export function echo({collection, model}, vue) {
  $('.record').remove()

  window.vue = vue
  for(let i = 0; i < collection.length; i++) {
    var curObj = collection[i], side = curObj.side, plate, dom
    if(side == 'twoSided') dom = '.back-model-bg'
    else dom = '.model-bg'

    // 获取dom
    plate = document.querySelector(dom)
    var node = $(dom).find('.mold').clone(true).attr({
      'class': 'template',
      'id': curObj.id
    }).addClass('record').css({
      'display': 'block',
      'left': (curObj.x * vue.bl) + 'px',
      'top': (curObj.y * vue.bl) + 'px',
    })

    // 样式回显
    styleEcho(node, curObj)

    // 添加事件
    addEvent(node, plate)

    $(dom).append(node)
  }

  console.log(model)
  // model 样式回显
  $('.model-bg').css({
    'width': model.width * vue.bl+'px',
    'height': model.height * vue.bl+'px',
    'background-color': model.color,
    'background-image': `url(${url + model.image})`,
  })
  $('.back-model-bg').css({
    'width': model.width * vue.bl+'px',
    'height': model.height * vue.bl+'px',
    'background-color': model.backColor,
    'background-image': `url(${url + model.backImage})`,
  })
}

function styleEcho(tNode, curObj) {
  let { boxshadow, border } = curObj
  if(curObj.eleType == 'image'){
    $(tNode).css({
      'background-image': `url(${url + curObj.image})`,
    });
    if(!curObj.image) $(tNode).find('.cte').html('image');
    // getBase64Image(vue.API.echoImage(curObj.image))
  } else if(curObj.eleType == 'text') {
    // font 相关
    let { font, conten } = curObj
    $(tNode).find('.cte').html(conten || 'text');
    $(tNode).find('.cte').css({
      'font-family': font.family,
      'font-size': font.size+'px',
      'letter-spacing': font.spacing+'px',
      // 'line-height': font.lineHeight * vue.bl +'px',
      'color': font.color,
      'text-align': font.textAlign,
      'text-align-last': font.textAlign == 'justify' ? 'justify' : 'inherit',
      'font-weight': font.fontWeight,
      'text-decoration': font.textDecoration,
      'font-style': font.italics,
    })
  }

  $(tNode).css({
    'width': curObj.width * vue.bl + 'px',
    'height': curObj.height * vue.bl + 'px',
    'opacity': curObj.opacity / 100,
    'border': `${border.width}px ${border.style} ${border.color}` ,
    'border-radius': border.radius + 'px',
    'z-index': curObj.zIndex,
    'box-shadow': `${boxshadow.x}px ${boxshadow.y}px ${boxshadow.vague}px ${boxshadow.size}px ${boxshadow.color}`
  });

  if(curObj.variable) {
    $(tNode).css('border', `1px solid red`);
  }
}

// 全局添加 keyup事件
export function addKeyup(vue) {
  document.querySelector('body').onkeydown = e => {
    // 删除键
    if(e.keyCode == 46 && vue.curObj.id) {
      vue.collection = vue.collection.filter(its => its.id != vue.curObj.id && its)
      $('div').remove("#"+vue.curObj.id)
      vue.curObj = {}
    }

    // 上移键
    if(e.keyCode == 38 && vue.curObj.id) {
      vue.curObj.y = (vue.curObj.y - 0.1).toFixed(2) * 1
      $("#"+vue.curObj.id).css('top', vue.curObj.y * vue.bl + 'px')
      return false;
    }

    // 下移键
    if(e.keyCode == 40 && vue.curObj.id) {
      vue.curObj.y = (vue.curObj.y + 0.1).toFixed(2) * 1
      $("#"+vue.curObj.id).css('top', vue.curObj.y * vue.bl + 'px')
      return false;
    }

    // 左移键
    if(e.keyCode == 37 && vue.curObj.id) {
      vue.curObj.x = (vue.curObj.x - 0.1).toFixed(2) * 1
      $("#"+vue.curObj.id).css('left', vue.curObj.x * vue.bl + 'px')
      return false;
    }

    // 右移键
    if(e.keyCode == 39 && vue.curObj.id) {
      vue.curObj.x = (vue.curObj.x + 0.1).toFixed(2) * 1
      $("#"+vue.curObj.id).css('left', vue.curObj.x * vue.bl + 'px')
      return false;
    }
  }
}
