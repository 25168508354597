<template>
  <div class="right-body" tabindex="0">

    <div class="rules" @mousemove="move($event)" @mouseup="up($event)" style="user-select: none;">
      <div class="empty-x" :style="{'width': ex * zoom + 'px'}"></div>
      <div class="empty-y" :style="{'height': ey * zoom + 'px'}"></div>
      <div class="clear" title="清空提示线" @click.self='clearLine'></div>

      <!-- 卡尺 - x -->
      <div class="rules-x" :style="{'width': `calc(${rx * zoom}px - 38px)`}" @mousedown.self="xDown($event)">
        <span class="ruler-x-n" v-for="item in Math.floor(((rx * zoom) - 40)/ 37.8)" :style="{'left': `calc(1in / 2.54 * ${item - 1} + 2px)`}" :key="item">{{ (item - 1) }}</span>
      </div>

      <!-- 卡尺 - y -->
      <div class="rules-y" :style="{'height': `calc(${ry * zoom}px - 38px)`}" @mousedown.self="yDown($event)">
        <span class="ruler-y-n" v-for="item in Math.floor(((ry * zoom) - 40) / 37.8)" :style="{'top': `calc(1in / 2.54 * ${item - 1} + 2px)`}" :key="item">{{ (item - 1) }}</span>
      </div>

      <!-- 模板 -->
      <div class="model">
        <slot></slot>
      </div> 

    </div>

    <!-- 禁用遮罩 -->
    <div class="disable-shade" v-if="!disableShade" @click="clickShade($event)" ref="shade"></div>
  </div>
</template>

<script>
// 引入打印 文件  比例： 1:50
import { addLineX, addLineY } from  '@/plugins/ruler-line.js'
import $ from 'jquery'

export default {
  props: ['zoom', 'disableShade'],
  data() { 
    return {
      ex: 3150,
      ey: 2000,
      rx: 3150,
      ry: 2000,

      curObj: {},
      tNode: null, // 当前选中的line dom
      open: null, // 可移动开关
      oldY: null, // 记录旧 y值
      oldX: null, // 记录旧 x值
      switch: null // y: 左右拖动 line x: 上下拖动 line
    }
  }, 
  watch: {
    zoom: function(val) {
      console.log(val)
    }
  },
  methods: {
    // 点击遮罩
    clickShade(e) {
      return 
      let {offsetX, offsetY} = e,
      width = $('.model-bg').outerWidth(),
      height = $('.model-bg').outerHeight()
      // console.log(width)
      this.$refs.shade.ondblclick = ev => {
        if((offsetX >= 38 && offsetX <= 38 + width) && (offsetY >= 28 && offsetY <= 28 + height)) {
          this.$emit('dblclick')
        }
      }
    },
    // 清空所有提示线
    clearLine(){
      console.log(document.querySelectorAll('.tips'))
      // 所有line dom
      var allLineDom = document.querySelectorAll('.tips')

      // 删除
      for(let item of allLineDom){
        item.parentNode.removeChild(item)
      }
    },
    // 鼠标按下 - x
    xDown(e){
      console.log(e)
      // 不支持右键
      if(e.buttons == 2) return
      this.open = true
      this.oldY = e.screenY
      this.switch = 'x'

      // 创建 横 - line 
      addLineX(e.path[1], e.offsetY, this)
    }, 
    // 鼠标按下 - y
    yDown(e){
      console.log(e)
      // 不支持右键
      if(e.buttons == 2) return
      this.open = true
      this.oldX = e.screenX
      this.switch = 'y'

      // 创建 横 - line 
      addLineY(e.path[1], e.offsetX, this)
    }, 
    // 鼠标移动
    move(e){
      if(!this.open ){
        return 
      }
      console.log(this.switch)
      // 取出本地 缩放比例 
      var bl = localStorage.getItem('bl') || 1
      // console.log(e)
      if(this.switch == 'x'){
        // 计算移动的距离 y
        var chaVal = ((e.screenY - this.oldY) / bl).toFixed(2),
            oldTop = +this.tNode.style.top.replace('px', ''),
            val = +oldTop + (+chaVal)
      
        // 更新top值
        this.tNode.style.top = val + 'px'

        // 更新坐标值
        if((val - 38) >= 0)
          $(this.tNode).find('.y-value').text((val - 38) / 5)

        // 更新旧值
        this.oldY = e.screenY
      } else if(this.switch == 'y'){
        // 计算移动的距离 x
        var chaVal = ((e.screenX - this.oldX) / bl).toFixed(2),
            oldLeft = +this.tNode.style.left.replace('px', ''),
            val = +oldLeft + (+chaVal)

        // 更新left值
        this.tNode.style.left = val + 'px'

        // 更新坐标值
        if((val - 38) >= 0)
          $(this.tNode).find('.x-value').text((val - 38) / 5)

        // 更新旧值
        this.oldX = e.screenX
      }
    },
    // 鼠标抬起
    up(e){
      this.open = false
    },
  }
}

</script>

<style lang="less" scoped>
  .right-body {
    width: 100%;
    height: calc(100%);
    background-color: #eee;
    position: relative;
    outline: none;
  }

  .disable-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }

  .rules {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
  }

  .rules-x {
    position: absolute;
    left: 38px;
    z-index: 100;
    // overflow-x: hidden;
    // top: 10px;
    cursor: pointer;
    width: 315%;
    height: 18px;
    background: url('../../../assets/ruler.png') repeat-x;
    background-size: calc(1in / 2.54) 100%;
  }

  .empty-x {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    width: 315%;
    height: 18px;
    background-color: #fff;
  }

  .ruler-x-n {
    top: 1px;
    left: 2px;
    position: absolute;
    font: 10px/1 Arial,sans-serif;
    color: #333;
  }

  .rules-y {
    position: absolute;
    top: 28px;
    z-index: 100;
    cursor: pointer;
    left: 10px;
    height: 200%;
    width: 18px;
    background: url('../../../assets/ruler-y.png') repeat-y;
    background-size: 100% calc(1in / 2.54);
  }

  .empty-y {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    height: 200%;
    width: 28px;
    background-color: #fff;
  }

  .ruler-y-n {
    top: 1px;
    left: 2px;
    position: absolute;
    font: 10px/1 Arial,sans-serif;
    color: #333;
  }

  .model {
    position: absolute;
    top: 28px;
    left: 38px;
    display: flex;
    // width: 300px;
    // height: 200px;
    // background-color: #fff;
  }

  .clear {
    width: 10px;
    height: 10px;
    position: absolute;
    // top: 10px;
    left: 10px;
    background-color: #ccc;
    z-index: 51;
    cursor: pointer;
  }

</style>

<style>
  .linex {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 315%;
    height: 1px;
    background-color: #000;
    z-index: 30;
    cursor: move;
  }

  .line-x-box {
    position: absolute;
    left: 90%;
    top: -7.5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: inherit;
  }

  .line-x-box .y-value {
    position: absolute;
    top: 20px;
    font-size: 12px;
    color: #333;
  }

  .liney {
    position: absolute;
    left: 0;
    top: 0;
    height: 200%;
    width: 1px;
    background-color: #000;
    z-index: 30;
    cursor: move;
  }

  .line-y-box {
    position: absolute;
    top: 90%;
    left: -7.5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: inherit;
    color: #fff;
  }

  .line-y-box .x-value {
    position: absolute;
    left: 20px;
    font-size: 12px;
    color: #333;
  }

  .linex:hover {
    background-color: rgb(153, 28, 28);
  }

  .liney:hover {
    background-color: rgb(153, 28, 28);
  }

  .select-line {
    background-color: rgb(153, 28, 28);
  }

  .line-func {
    box-shadow: 1px 1px 5px 1px #ccc;
    padding: 3px;
    width: 30px;
    font-size: 14px;
    color: #666;
    position: absolute;
    display: none;
    cursor: pointer;
  }

  .linex:hover .line-func, .liney:hover .line-func {
    display: block;
  }

  .del {
    display: none;
  }
</style>