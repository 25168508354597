export let family = [
  {value: 'SimSun', key: '宋体', params: 'SimSun'},
  {value: 'SimHei', key: '黑体', params: 'simhei'},
  {value: 'Microsoft YaHei', key: '微软雅黑', params: 'yahei'},
  {value: 'Microsoft JhengHei', key: '微软正黑体', params: 'Microsoft-JhengHei'},
  {value: 'KaiTi', key: '楷体', params: 'kaiti'},
  {value: 'NSimSun', key: '新宋体', params: 'nsimsun'},
  // {value: 'PingFang SC', key: '苹方'},
  // {value: 'STHeiti', key: '华文黑体'},
  // {value: 'STZhongsong', key: '华文中宋'},
  // {value: 'STHupo', key: '华文琥珀'},
  // {value: 'STXinwei', key: '华文新魏'},
  // {value: 'STZhongsong', key: '华文中宋'},
  // {value: 'STLiti', key: '华文隶书'},
  // {value: 'STXingkai', key: '华文行楷'},
  // {value: 'Hiragino Sans GB', key: '冬青黑体简'},
  // {value: 'YouYuan', key: '幼圆'},
  // {value: 'LiSu', key: '隶书'},
  // {value: 'STXihei', key: '华文细黑'},
  // {value: 'STKaiti', key: '华文楷体'},
  // {value: 'STSong', key: '华文宋体'},
  // {value: 'STZhongsong', key: '华文中宋'},
  // {value: 'STCaiyun', key: '华文彩云'},
  // {value: 'FZShuTi', key: '方正舒体'},
  // {value: 'FZYaoti', key: '方正姚体'},
]